import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import type { AppProps } from '@finn-no/content-module';
import App from './App.js';

const rootNodes = document.querySelectorAll('content-module-podlet-isolated');

function getLinkElementInOnlyHeaderModule(): HTMLElement | null {
    const moduleNodes = Array.from(
        document.querySelectorAll('content-module-podlet-isolated'),
    );
    let targetElement: HTMLElement | null = null;

    moduleNodes.forEach((moduleNode) => {
        if (targetElement) return;

        if (moduleNode instanceof HTMLElement && moduleNode.shadowRoot) {
            const moduleLogoElement = Array.from(
                moduleNode.shadowRoot.querySelectorAll('[data-header-state]'),
            ).find(
                (el) => el.getAttribute('data-header-state') === 'onlyHeader',
            ) as HTMLElement | null;

            if (moduleLogoElement) {
                const linkElement = moduleLogoElement.querySelector(
                    'a[href="#adview-content-module-logo-only-header-content"]',
                );

                if (linkElement) {
                    targetElement = linkElement as HTMLElement;
                }
            }
        }
    });

    return targetElement;
}

function addHandlerForTopModuleLinkClick(
    event: Event,
    articleHostNode: HTMLElement & { shadowRoot: ShadowRoot },
): void {
    event.preventDefault();
    const targetElement = articleHostNode.shadowRoot.querySelector(
        '#adview-content-module-logo-only-header-content',
    );
    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    } else {
        console.log('Target element not found inside Shadow DOM.');
    }
}

function setupEventHandler(linkElement: HTMLElement, rootNode: HTMLElement) {
    linkElement.addEventListener('click', (event) => {
        addHandlerForTopModuleLinkClick(
            event,
            rootNode as HTMLElement & {
                shadowRoot: ShadowRoot;
            },
        );
    });
}

rootNodes.forEach((rootNode) => {
    if (rootNode instanceof HTMLElement && rootNode.shadowRoot) {
        const renderNode = rootNode.shadowRoot.querySelector(
            '[data-content-module-root]',
        );
        const dataNode = rootNode.shadowRoot.querySelector(
            '[data-content-module-data]',
        ) as HTMLElement;
        const contentNode = rootNode.shadowRoot.querySelector(
            '[data-content-module]',
        );
        const initialState = (() => {
            try {
                return dataNode?.textContent
                    ? (JSON.parse(dataNode.textContent) as AppProps)
                    : null;
            } catch (error) {
                console.error('Failed to parse initial state:', error);
                return null;
            }
        })();

        const dataHeaderState = !renderNode
            ? ''
            : renderNode.getAttribute('data-header-state');

        if (initialState?.headerState === 'original') {
            // it's not possible to link to elements inside a shadow dom
            // this hack catches the click event from link in top module and scrolls to link

            // need this timeout to let the hydration finish
            // event listener will be resat if it's done before
            setTimeout(() => {
                const linkElement = getLinkElementInOnlyHeaderModule();
                if (linkElement) {
                    setupEventHandler(linkElement, rootNode);
                } else {
                    const observer = new MutationObserver(() => {
                        const linkElementAfterDomLoaded =
                            getLinkElementInOnlyHeaderModule();
                        if (linkElementAfterDomLoaded) {
                            setupEventHandler(
                                linkElementAfterDomLoaded,
                                rootNode,
                            );
                            if (observer) observer.disconnect();
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            clearTimeout(timeout);
                        }
                    });

                    observer.observe(document.body, {
                        childList: true,
                        subtree: true,
                    });

                    // no need to observe in cases where just the articles are displayed
                    const timeout = setTimeout(() => {
                        if (observer) observer.disconnect();
                    }, 10000);
                }
            }, 500);
        }

        if (initialState && initialState.headerState !== dataHeaderState) {
            console.log('Unalike headerstate');
        } else if (contentNode && initialState) {
            hydrateRoot(contentNode, <App {...initialState} />);
        } else if (process.env.NODE_ENV !== 'production') {
            console.log('Missing app and/or data element');
        }
    } else {
        console.error(
            'rootNode does not have a shadowRoot or is not an HTMLElement:',
            rootNode,
        );
    }
});
